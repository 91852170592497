import { TimeTrackingRestrictionType } from "@farmact/model/src/model/AppCompany";
import { OrderStatus } from "@farmact/model/src/model/OrderStatus";
import { Role } from "@farmact/model/src/model/Role";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { useOrder } from "@/components/orders/utils/useOrder";
import { computed } from "@/util/functions";
import { useRole } from "./useRole";

type UseCanEditTimeTrackingReturn = {
    canEditTimeTracking: boolean;
    hint: string;
};

export function useCanEditTimeTracking(timeTracking: TimeTracking | null): UseCanEditTimeTrackingReturn {
    const { role } = useRole();
    const { appCompany } = useAppCompanyContext();
    const [order] = useOrder(timeTracking?.order?.orderId);

    const isAtLeastAccountant = role ? [Role.ADMIN, Role.OWNER, Role.ACCOUNTANT].includes(role) : true;
    const restriction = appCompany?.settings.employeeSettings.timeTrackingEditRestriction;
    const isCheckedOrBilled = order?.status === OrderStatus.CHECKED || order?.status === OrderStatus.BILLED;

    const canEditTimeTracking = useMemo(() => {
        if (!timeTracking) {
            return false;
        }
        if (!restriction) {
            return false;
        }
        if (isAtLeastAccountant) {
            return true;
        }
        if (isCheckedOrBilled) {
            return false;
        }
        if (restriction.type === TimeTrackingRestrictionType.FULL) {
            return false;
        }
        if (restriction.type === TimeTrackingRestrictionType.NONE) {
            return true;
        }
        if (restriction.type === TimeTrackingRestrictionType.DAYS) {
            return Math.abs(dayjs().diff(dayjs(timeTracking.startDateTime), "days")) < restriction.days;
        }
        return false;
    }, [restriction, isAtLeastAccountant, timeTracking, isCheckedOrBilled]);
    const hint = computed(() => {
        if (isCheckedOrBilled) {
            return "Die Zeiterfassung kann nicht bearbeitet werden, da der Auftrag abgerechnet oder kontrolliert wurde. Wende dich bitte an deinen Vorgesetzten.";
        }
        if (restriction?.type === TimeTrackingRestrictionType.FULL) {
            return "Zeiterfassungen dürfen nicht nachträglich bearbeitet oder hinzugefügt werden. Wende dich bitte an deinen Vorgesetzten.";
        }
        if (restriction?.type === TimeTrackingRestrictionType.DAYS) {
            return `Zeiterfassungen, die mehr als ${restriction.days} Tage in der Vergangenheit oder in der Zukunft liegen, müssen von einem Vorgesetzten hinzugefügt oder bearbeitet werden.`;
        }
        return "";
    });

    return { canEditTimeTracking, hint };
}
