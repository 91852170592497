import Field from "@farmact/model/src/model/Field";
import { LoadingOrUnloadingPoint } from "@farmact/model/src/model/LoadingOrUnloadingPoint";
import { Marker } from "@farmact/model/src/model/Marker";
import { Track } from "@farmact/model/src/model/Track";
import { Context, createContext } from "react";
import { useUserContext } from "@/components/authentication/Session/useUserContext";

type CustomerMapContextType = {
    dataLoading: boolean;
    mapStructureData: CustomerPortalMapStructureData;
    onMapStructuresDataChange?: (
        updateFunc: (previous: CustomerPortalMapStructureData) => CustomerPortalMapStructureData
    ) => void;
    companyId: string | null;
    canEditMapStructures: boolean;
};

const defaultCustomerMapContextValue: CustomerMapContextType = {
    mapStructureData: {
        fields: [],
        tracks: [],
        customerMarkers: [],
        customerLoadingPoints: [],
    },
    onMapStructuresDataChange: () => {},
    dataLoading: false,
    companyId: null,
    canEditMapStructures: false,
};

const CustomerMapContext: Context<CustomerMapContextType> =
    createContext<CustomerMapContextType>(defaultCustomerMapContextValue);

export default CustomerMapContext;

export type CustomerPortalMapStructureData = {
    fields: Field[];
    tracks: Track[];
    customerMarkers: Marker[];
    customerLoadingPoints: LoadingOrUnloadingPoint[];
};

export function useIsAccessingViaCustomerPortal() {
    const { customClaims } = useUserContext();
    if (!customClaims?.customerIds) {
        return false;
    }
    const isCustomerView = Object.entries(customClaims.customerIds).length > 0 && !customClaims.defaultCompanyId;
    return isCustomerView;
}
