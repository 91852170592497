import dayjs from "dayjs";
import { ActivityPreset } from "./AppCompany";
import { Customer } from "./Customer";
import Field from "./Field";
import { LoadingOrUnloadingPoint } from "./LoadingOrUnloadingPoint";
import { MachineVariantIdentifier } from "./Machine";
import { Marker } from "./Marker";
import { OperatingUnit } from "./OperatingUnit";
import { Order } from "./Order";
import { Service } from "./services/Service";
import { Track } from "./Track";

export class TimeTracking {
    public id: string;
    public appUserId: string;
    public employeeId: string;
    public order: TimeTrackingOrder | null;
    public name: string;
    public internalActivityPresetId: ActivityPreset["id"] | null;
    public orderActivityPresetId: ActivityPreset["id"] | null;
    public startDateTime: string;
    public endDateTime: string;
    public note: string;
    public operatingUnitId: OperatingUnit["id"] | null;
    /**
     * only used for firestore aggregation queries
     */
    public readonly durationHours: number;

    constructor(initialValues?: Partial<Omit<TimeTracking, "durationHours">>) {
        this.id = initialValues?.id ?? "";
        this.appUserId = initialValues?.appUserId ?? "";
        this.employeeId = initialValues?.employeeId ?? "";
        this.order = initialValues?.order ?? null;
        this.name = initialValues?.name ?? "";
        this.internalActivityPresetId = initialValues?.internalActivityPresetId ?? null;
        this.orderActivityPresetId = initialValues?.orderActivityPresetId ?? null;
        this.startDateTime = initialValues?.startDateTime ?? dayjs().second(0).millisecond(0).toISOString();
        this.endDateTime = initialValues?.endDateTime ?? dayjs(this.startDateTime).add(1, "hour").toISOString();
        this.note = initialValues?.note ?? "";
        this.durationHours = calculateTimeTrackingDurationHours(this);
        this.operatingUnitId = initialValues?.operatingUnitId ?? null;
    }
}

export type TimeTrackingOrder = {
    orderId: Order["id"];
    orderArchived: boolean;
    orderNumber: Order["orderNumber"];
    orderRunId: string | null;
    customerId: Customer["id"] | null;
    serviceId: Service["id"] | null;
    mapStructure: AnyTimeTrackingMapStructure | null;
    machineVariants: MachineVariantIdentifier[];
};

export enum TimeTrackingMapStructureType {
    FIELD = "FIELD",
    TRACK = "TRACK",
    MARKER = "MARKER",
    LOADING_OR_UNLOADING_POINT = "LOADING_OR_UNLOADING_POINT",
}

export type TimeTrackingWithMapStructureType<T extends AnyTimeTrackingMapStructure> = TimeTracking & {
    order: Omit<TimeTracking["order"], "mapStructure"> & { mapStructure: T };
};

export type TimeTrackingField = {
    type: typeof TimeTrackingMapStructureType.FIELD;
    fieldId: Field["id"];
};

export type TimeTrackingTrack = {
    type: typeof TimeTrackingMapStructureType.TRACK;
    trackId: Track["id"];
};

export type TimeTrackingMarker = {
    type: typeof TimeTrackingMapStructureType.MARKER;
    markerId: Marker["id"];
};

export type TimeTrackingLoadingOrUnloadingPoint = {
    type: typeof TimeTrackingMapStructureType.LOADING_OR_UNLOADING_POINT;
    loadingOrUnloadingPointId: LoadingOrUnloadingPoint["id"];
};

export type AnyTimeTrackingMapStructure =
    | TimeTrackingField
    | TimeTrackingTrack
    | TimeTrackingMarker
    | TimeTrackingLoadingOrUnloadingPoint;

export function calculateTimeTrackingDurationHours(timeTracking: TimeTracking): number {
    return dayjs(timeTracking.endDateTime).diff(timeTracking.startDateTime, "hours", true);
}
