import { Employee } from "@farmact/model/src/model/Employee";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { TimeTrackingsView } from "@/components/inputs/TimeTrackingsViewSelect/TimeTrackingsView";

dayjs.extend(isBetween);

export function getTargetHours(
    employee: Employee | null,
    view: TimeTrackingsView,
    startDate: Dayjs,
    endDate: Dayjs
): number | null {
    if (!employee) {
        return null;
    }
    if (employee.targetWorkHoursWeekly) {
        return getTargetHoursForInterval(employee, startDate, endDate);
    }
    if (view === TimeTrackingsView.MONTH) {
        return getTargetHoursForMonth(employee, startDate);
    } else if (view === TimeTrackingsView.YEAR) {
        let sum = 0;
        let month = startDate.startOf("year");
        for (let i = 0; i < 12; i++, month = month.add(1, "month")) {
            sum += getTargetHoursForMonth(employee, month);
        }
        return sum;
    } else {
        return null;
    }
}

export function getTargetHoursForInterval(employee: Employee, startDate: Dayjs, endDate: Dayjs) {
    if (!employee.targetWorkHoursWeekly) {
        return 0;
    }

    if (!employee.hiringDate && !employee.exitDate) {
        const workDays = getWorkDaysBetweenTwoDates(startDate.toDate(), endDate.toDate());
        return (employee.targetWorkHoursWeekly / 5) * workDays;
    }
    const hiringDate = !employee.hiringDate ? startDate : dayjs(employee.hiringDate).startOf("day");
    if (hiringDate.isAfter(endDate)) {
        return 0;
    }
    const exitDate = !employee.exitDate ? endDate : dayjs(employee.exitDate).startOf("day");
    if (exitDate.isBefore(startDate)) {
        return 0;
    }
    const relevantStartDate = hiringDate.isBetween(startDate, endDate) ? hiringDate.toDate() : startDate.toDate();
    const relevantEndDate = exitDate.isBetween(startDate.subtract(1, "day"), endDate)
        ? exitDate.toDate()
        : endDate.toDate();
    const workDays = getWorkDaysBetweenTwoDates(relevantStartDate, relevantEndDate);
    return (employee.targetWorkHoursWeekly / 5) * workDays;
}

function getTargetHoursForMonth(employee: Employee, dateOfMonth: Dayjs) {
    if (!employee.hiringDate && !employee.exitDate) {
        return employee.targetHoursMonthly;
    }
    const startOfMonth = dateOfMonth.startOf("month");
    const endOfMonth = dateOfMonth.endOf("month");
    const hiringDate = !employee.hiringDate ? startOfMonth : dayjs(employee.hiringDate).startOf("day");
    const exitDate = !employee.exitDate ? endOfMonth : dayjs(employee.exitDate).startOf("day");

    if (!hiringDate.isAfter(startOfMonth) && !exitDate.isBefore(endOfMonth)) {
        return employee.targetHoursMonthly;
    }
    if (hiringDate.isAfter(endOfMonth) || exitDate.isBefore(startOfMonth)) {
        return 0;
    }

    const relevantStartDate = hiringDate.isBetween(startOfMonth, endOfMonth)
        ? hiringDate.toDate()
        : startOfMonth.toDate();
    const relevantEndDate = exitDate.isBetween(startOfMonth, endOfMonth) ? exitDate.toDate() : endOfMonth.toDate();
    const workDays = getWorkDaysBetweenTwoDates(relevantStartDate, relevantEndDate);
    return (
        (workDays / getWorkDaysBetweenTwoDates(startOfMonth.toDate(), endOfMonth.toDate())) *
        employee.targetHoursMonthly
    );
}

export function getWorkDaysBetweenTwoDates(startDate: Date, endDate: Date): number {
    let workDays = 0;
    const current = new Date(startDate);
    while (dayjs(current).isSameOrBefore(endDate, "day")) {
        if (current.getDay() !== 0 && current.getDay() !== 6) {
            workDays++;
        }
        current.setDate(current.getDate() + 1);
    }
    return workDays;
}
