import { Overtime } from "@farmact/model/src/model/Overtime";
import { faBusinessTime, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton } from "@ionic/react";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, useState } from "react";
import { IconWithTooltip } from "@/components/IconWithTooltip/IconWithTooltip";
import { timeFormatter } from "@/util/pdf/timeFormatter";
import { EditOvertimeModal } from "./EditOvertimeModal/EditOvertimeModal";
import "./tableGroupOvertime.scss";
import "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/TableGroupTopLevel/tableGroupTopLevel.scss";

type TableGroupOvertimeProps = ComponentPropsWithoutRef<"div"> & {
    overtime: Overtime;
    canEdit: boolean;
};

export const TableGroupOvertime = (props: TableGroupOvertimeProps) => {
    const { overtime, canEdit, ...divProps } = props;
    const [editOverTimeModalIsOpen, setEditOvertimeModalIsOpen] = useState(false);

    return (
        <div {...divProps} className={"table-group-top-level table-group-overtime"}>
            <div className="table-group-top-level__cell table-group-top-level__date">
                <IconWithTooltip icon={faBusinessTime} iconClassName="group-icon" color="primary">
                    {overtime.note || "Manuell eingepflegte Überstunden"}
                </IconWithTooltip>

                <span className="group-date__day">{formatDay(overtime.date)}</span>
                <span className="group-date__date">{formatDate(overtime.date)}</span>
            </div>
            <div className="table-group-top-level__cell table-group-top-level__work-time"></div>
            <div className="table-group-top-level__cell table-group-top-level__time">
                {timeFormatter.formatFromHours(overtime.amount)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__break"></div>
            <div className="table-group-top-level__cell table-group-top-level__total">
                {timeFormatter.formatFromHours(overtime.amount)}
            </div>
            <div className="table-group-top-level__cell table-group-top-level__activities"></div>
            <div className="table-group-top-level__cell table-group-top-level__notes">{overtime.note}</div>
            <div className="table-group-top-level__cell">
                {canEdit && (
                    <IonButton
                        size="small"
                        fill="clear"
                        color="primary"
                        onClick={() => setEditOvertimeModalIsOpen(true)}>
                        <FontAwesomeIcon icon={faPencil} />
                    </IonButton>
                )}
            </div>
            <EditOvertimeModal
                isOpen={editOverTimeModalIsOpen}
                onDismiss={() => setEditOvertimeModalIsOpen(false)}
                overtime={overtime}
            />
        </div>
    );
};

function formatDay(day: string): string {
    return dayjs(day).locale("de").format("dd.");
}

function formatDate(day: string): string {
    return dayjs(day).locale("de").format("DD.MM");
}
