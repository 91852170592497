// TODO 9372: Move to utils package

type Identifiable = {
    id: string;
};

export function deduplicateById<T extends Identifiable>(...data: Array<T[]>): T[] {
    const m = new Map<string, T>();

    for (const list of data) {
        for (const item of list) {
            m.set(item.id, item);
        }
    }

    return Array.from(m.values());
}
