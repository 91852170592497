import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { Customer } from "@farmact/model/src/model/Customer";
import Field from "@farmact/model/src/model/Field";
import { query, where } from "firebase/firestore";
import { useCallback, useMemo } from "react";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "@/util/customHooks/useStableArray";

type UseFieldsOptions = {
    companyId?: string;
    includeArchived?: boolean;
};
type UseFieldsReturn = {
    fields: Field[];
    loading: boolean;
};

export function useFields(fieldIds: string[], options: UseFieldsOptions = {}): UseFieldsReturn {
    const { companyId } = options;

    const stableFieldIds = useStableIdArray(fieldIds);

    const queryGenerator = useCallback(
        (fieldIds: string[]) => {
            return query(
                Firebase.instance().getAllFields({
                    companyId,
                    include: options.includeArchived ? "all" : "non-archived",
                }),
                where("id", "in", fieldIds)
            );
        },
        [companyId, options.includeArchived]
    );

    const [fields, loading] = useMergedQueryData(stableFieldIds, queryGenerator);

    return {
        fields,
        loading,
    };
}

type UseCustomerFieldsParams = {
    companyId: AppCompany["id"] | undefined;
    customerId: Customer["id"] | undefined;
    archived: boolean;
};

export function useCustomerFields(params: UseCustomerFieldsParams) {
    const { customerId, companyId, archived } = params;

    const fieldsQuery = useMemo(() => {
        if (!customerId) {
            return;
        }

        return Firebase.instance().getAllFieldsForCustomer({
            customerId,
            companyId,
            include: archived ? "archived" : "non-archived",
        });
    }, [customerId, companyId, archived]);

    return useCollectionData(fieldsQuery, [fieldsQuery]);
}
